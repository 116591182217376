import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import { useFetch } from '../../hooks/useFetch';
import { UserContext } from '../../context/UserContext';
import { useAuthToken } from '../../hooks/useAuthToken';
import { getPlanStatus, Toast, BannerRemainingDays, PwdEye } from '../../components/controls/UIControls'
import Modal from '@mui/material/Modal';
import WebChatZoomCrm from '../../assets/zoom-messenger-react/lib/addBubble.js';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FinishedCourses } from './FinishedCoursesTab';
import { landingImages, MINIMUM_CERT_SCORE } from './shared';
import { InProgressCourses } from './InProgressNewCoursesTab';
import { AmountFormat } from '../controls/AmountFormat';
import moment from 'moment';
import { PasswordValidator } from '../controls/PasswordValidator';
import * as CONSTANTS from '../../assets/constants';
import { useCourseTaker } from '../../hooks/useCourseTaker';

// Sección perfil de usuario
export const ProfileSection = () => {
    const params = new URLSearchParams(window.location.search)

    const { register, handleSubmit, watch, setValue, control, formState: { errors }} = hookUseForm({reValidateMode: "onChange"});
    const {user, setUser} = useContext(UserContext);
    const [isDisabled, setisDisabled] = useState(true);
    const [bannerProperties, setBannerProperties] = useState({show:false});

    const [showModalEmailNotChange, setShowModalEmailNotChange] = useState(false);
    const [showModalConfirmUpdate, setShowModalConfirmUpdate] = useState(false);
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [profileMenuSection, setProfileMenuSection] = useState([false, false, false])

    const [profileError, setProfileError] = useState('');

    const navigate = useNavigate();
    let userNotContext ={};
    const [data, handleFetch] = useFetch();
    const {authToken, setSessionUser, getSavedAuthToken}= useAuthToken();
    const location= useLocation();
    const [toastProperties, setToastProperties] = useState({show: false});
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }

    const [showModalChangePassword, setShowModalChangePassword] = useState(false);

    useEffect(()=> {
        

        //This If allows recive a parameter to render a specific section of the profile page
        //If you want to go directly to My courses you should send as a URL parameter 'mis-cursos'
        //If you want to go directly to Payments you should send as a URL parameter 'pagos-y-facturas'
        //If you want to go directly to the information section of the user you shouldn't send anything
        //But by default, if it doesn't recognize the parameter, it will render the information section
        
        if(params.get('profileSection')==='mis-cursos'){
            setProfileMenuSection([false, true, false])
        }else if(params.get('profileSection')==='pagos-y-facturas'){
            setProfileMenuSection([false, false, true])
        }else{
            setProfileMenuSection([true, false, false])
        }
        // Handle para consultar datos del usuario
        let isMounted = true;
        const response = handleFetch({
            url:'users/info',
            method: 'POST',
            headers: {Authorization: `Bearer ${authToken}`} 
        });
        response
        .then( r => {
            userNotContext = r;
            if(isMounted){

                switch (userNotContext.documentType) {
                    case "CC":
                        setValue("documentType", "Cédula de Ciudadanía")
                    break;
                case "CE":
                    setValue("documentType", "Cédula de Extranjería")
                    break;
                case "DIE":
                    setValue("documentType", "Documento de Identidad Extranjera")
                    break;
                case "PA":
                    setValue("documentType", "Pasaporte")
                    break;
                case "TI":
                    setValue("documentType", "Tarjeta de Identidad")
                    break;
            
                default:
                    setValue("documentType", "No identificado")
                    break;
                }
                setValue("firstName", userNotContext.name);
                setValue("lastName", userNotContext.lastname);
                setValue("userEmail", userNotContext.email);
                setValue("userDocument", userNotContext.document);
                setValue("phone", "+"+userNotContext.phone);
                setisDisabled(false);
                setUser(userNotContext);
            } 
        },
            error => {
                if(error.errors) {
                    setProfileError(error.errors[0].message)
                }
                        
            });
                   
        return () => {
            isMounted = false;
            };
                },[]);
    // Función de cambio de correo
    const handleOnClickEmail = (e) =>{
        if(e.userEmail.includes("@universidadean.edu.co")){
            setShowModalEmailNotChange(true)
        }
    };
    // Handle para cerrar modals
    const handleCloseModal = () => {
        setShowModalEmailNotChange(false);
        setShowModalConfirmUpdate(false);
        setShowModalUpdated(false);
        setShowModalChangePassword(false);
    }
    const handleChangePassword = (e) =>{
        setShowModalChangePassword(true)
    };
    // Handle para actualizar perfil
    const updateProfile = ( (formValues) =>{
        if(user.email===formValues.userEmail){

            const response = handleFetch({
                url:'users/update',
                method: 'POST', 
                data: {
                    lastname:formValues.lastName,
                    name:formValues.firstName,
                    phone: formValues.phone.replace('+', ''), 
                },
                headers: {Authorization: `Bearer ${authToken}`} 

            });
            response
                .then((r) => {
                    setSessionUser(JSON.stringify(r), getSavedAuthToken()!==null);
                    setUser(r);
                        setToastProperties({
                            show:true,
                            type:'success',
                            title: '¡Tus datos se han actualizado correctamente!',
                            message: 'Recuerda actualizar tus datos ya que serán usados para generar tus certificados',
                            position: {horizontal:'left'},
                        })
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {

                            setProfileError(error.errors[0].message)
                        }
                        
                    })
        
        }else{
            setShowModalConfirmUpdate(true);
        }
    }); 
    // Función para actualizar datos de usuario incluyendo correo electrónico
    const handleConfirmUpdate = (formValues) =>{

        const response = handleFetch({
            url:'users/update',
            method: 'POST', 
            data: {
                email:formValues.userEmail,
                lastname:formValues.lastName,
                name:formValues.firstName,
                phone: formValues.phone.replace('+', ''), 
            },
            headers: {Authorization: `Bearer ${authToken}`} 

        });
        response
            .then((r) => {
                navigate('/verificar-email',{
                    state:{
                        email:formValues.userEmail,
                        lastname:formValues.lastName,
                        name:formValues.firstName,
                        phone: formValues.phone.replace('+', ''), 
                        changeEmail: true
                    }
                });
            },
                error => {
                    if(error.errors) {
                        setProfileError(error.errors[0].message)
                    }        
            });

    }
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || {show:false});
        window.history.replaceState(null,'');
    },[]);
return (
    <>
        <BannerRemainingDays />
        <div id="contWebChatZoomCrm">
            <WebChatZoomCrm
                elementId="contWebChatZoomCrm"
                backgroundColor="#3BAC53"
                color="#FFFFFF"
                socialGatewayUrl="https://socialgateway.zoomcem.com/modules/zoomWebChat/zoomMessengerLog.php?params=N3hMWEdVWndFVytYQnE4cU4xSm1pMlhRaHVvSXVPRmVuNjQrdGFvS0o4cVdaZXA1SG80azh6WDc2U3g5T3RMODdEME84bmdqNkZqMjNta1BIN1Rza3BhWHdzVDRSdERBUG1PSTlFWGcwM3BrVE5KU213ODUxWTc3ZEk4UmNKejhjN0NEd3RpQ1FQSTdPRHFNbWpZRjVtc1pvUmhyMUJUL2dsQldWeXhUUDVqSXdySWxtZ3YxVmxlMEJYVElLdkdMUnEva1B6YXBFQ3NUNzk0a2lKRkdFanIyaTAvQ01ibVRsVDFXKzlmNmJ1T0czcnM2d01zb1FhTDJyUC9XUDZicjZwQ0cvUUpCaTlCclM1RWY3eE5vYVNodGlTeXc5QlRJaTBtbFVldGs5S2FSWmd6VVRxS2dQQlFhNnpJSXV3UHZBNi81RkpuNytrYVU2Nm1nbzQ4dVJReS9RdlVxWEFZdUgrbFdtN2lZUlgzRXRnTGhiZWR4RzdBWkphNW41NDJFOEl0M2RHUXJRQTV1aGF0L1IwS290ZVVDUzl2TWQ0MVFTNDdoTFQxRDR2Z0VGVW1aWDYydSs1R2FiMHJaaHlkUlVOcWk4UlM4ZU01ZzdRUGYxV0ptVXpraEt0aUkyQzdSZmhQOGxJR0t1c0IyVmxVNWcxK1BraWFDYjcxUzJkdnA2Nko1RzBYNi9CU0FNZWhEc2FZcVRsdm05WUVWWWFnTFB1bHc3WXdDYmU3NWEvQjU2aVBVbTgyeFUrQzZNd3hZUXplbStvSXpBakhWQlB0M1N2b1dWeS8xSFFSdklkL0pwMEhMajJpWHJhVWxiZ2hYdU51c2dQMFcvaTdaMHNBS0p5aVNMMnpYeHByYVhQV08wWXlMVHR3VWNGR1c3U0FwWEE5emswVzdoMzBBbUxEOTMvdTBKSmxUdHlSdWEyVDZZSjVqb2Z6aHN3c1UzNit0eiszMWc4SGZCWnoreW9HUGloRFR4KzNnWXh1VjZPUkllbXhFUURkcm1hY2hiV1JpTXl6dzAzbFpVdGhqSWdub2JtcEZqZ2FYY0lQRXBtcjhkY2w0cnh0UHprWkRRR3ZCYktlR2l2VFAwREk0b3VxWExWZEVBL2FUYkJaK09jWTg5MUZ0d1dVeDhYckxFQjhKU2d4R2FJcGdjUlNSU0VkcWtCYk5XdFNqQjdTclJLM0t6L0xib0pqOHIwZVYwd2JxZGFCREtoaHlmN1BhbDVGSnZqZ1M3Y1kvcVptcnJFVGlBcjFJSGw5UUN2NjJHYVFDZE4reFBTUUNRSFEwN3VyTEQvaUprYUZzMDZ6azJTRTZ2VHo3aGdtY3ZOczQySXh5R0o4dVdOMG4zV3h1cWdDbTBUMnF3V2hwZXBSTkU4RmFRbWxGWTJlY0t1Wi9vRjBWNlYreE1UbjQ3Z2tudndmM2YxU1hMTFdNdmtkZW9nNEJOYWExaGZ1RDdrOU5tMmFRSi9WQ3h4Q3BaVW42eGdUQVRQeXlmVkEwOEdyazN5elJuS0NoS2RJdXI2OENIazVvK2JjM2Q3R2ZBclQrSWpQT2V6VkFEb0pFc0Zoc0NXeVZvT2t1NnkrMzUxZnNrc2tZRjlPQStoK045ditZWkVhdlUzenJjT0VDbWtsNlpRVWhTTDNqZVE9PQ=="/>
        </div>
        <div className='ProfilePage__banner-container'>
            <div className='ProfilePage__banner-text-container' style={{position: "absolute"}}>
                <p className='ProfilePage__banner-text'>
                    MI PERFIL
                </p>
                <p className='ProfilePage__banner-user-name'>
                    Hola, {user.name}
                </p>
            </div>
        <img alt='banner' className="d-lg-block d-none"  src={landingImages('./banner-profile-medium.jpg')} width="100%" />
        <img alt='banner'  className="d-none d-md-block d-lg-none"  src={landingImages('./banner-profile.png')} width="100%" />
        <img alt='banner'  className="d-block d-md-none d-lg-none"  src={landingImages('./banner-profile-small.png')} width="100%" />
        </div>
        <div>
        </div>
                <div className='row justify-content-center my-4'>
                    <ProfileMenuSection profileMenuSection={profileMenuSection} setProfileMenuSection={setProfileMenuSection} handleFetch={handleFetch} authToken={authToken} />
                    {profileMenuSection[0]
                    ?
                    <div className='ProfilePage__user-info-container'>
                        <div className='row p-3'>
                            <form onSubmit={handleSubmit(updateProfile)}>
                                <div className='ProfilePage__input-flex'>
                                    <div className="mb-3">
                                        <label className='ProfilePage__form-label'>Nombres</label>
                                        <input 
                                            disabled={isDisabled}
                                            type='text' 
                                            name='firstName'
                                            className={`form-control ProfilePage__input `}
                                            placeholder='Ingrese su nombre'
                                            {...register("firstName", { required: 'Es necesario ingresar un nombre' })}
                                        />
                                                                
                                    </div>
                                    <div className="mb-3">
                                        <label className='ProfilePage__form-label'>Apellidos</label>
                                        <input 
                                            disabled={isDisabled}
                                            type='text' 
                                            name='lastName'
                                            className={`form-control ProfilePage__input `}
                                            placeholder='Ingrese su apellido'
                                            {...register("lastName", { required: 'Es necesario ingresar un apellido' })}
                                        />                  
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className='ProfilePage__form-label'>Correo </label>
                                    <input 
                                        disabled={isDisabled || !user.canUpdateEmail}
                                        type='text' 
                                        name='userEmail'
                                        className={`form-control ProfilePage__input 
                                                    ${errors.userEmail?"ProfilePage__input--error":""}`}
                                        placeholder='nombre@correo.com'
                                        {...register("userEmail", { required: 'Es necesario ingresar un correo',
                                                                    onBlur: handleSubmit(handleOnClickEmail),
                                                                    pattern: {
                                                                        value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                        message: 'Debes ingresar un correo válido'
                                                                    }})}
                                    />
                                    {errors.userEmail && (
                                        <span role="alert" className='ProfilePage__inputError'>
                                        {errors.userEmail.message}
                                        </span>
                                    )}
                                    {!user.canUpdateEmail&&
                                        <div className='ProfilePage__info-email-no-change'>
                                            <span>
                                                Si deseas cambiar tu correo de comunidad eanista por otro correo, escríbenos a soporteeanx@universidadean.edu.co
                                            </span>
                                        </div>
                                    }                        
                                </div>
                                <div className='ProfilePage__input-flex'>

                                    <div className="">
                                        <label className='ProfilePage__form-label'>Tipo de documento</label>
                                        <input 
                                            className={`form-control ProfilePage__input `}
                                            disabled={true}
                                            name='documentType'
                                            placeholder='Tipo de documento'
                                            type='text' 
                                            {...register("documentType", { required: 'Es necesario seleccionar un tipo de documento'})}
                                        />                  
                                    </div>
                                    <div className="">
                                        <label className='ProfilePage__form-label'>Número de documento</label>
                                        <input 
                                            disabled={true}
                                            className={`form-control ProfilePage__input `}
                                            name='userDocument'
                                            placeholder='Número de documento'
                                            type='text' 
                                            {...register("userDocument", { 
                                                required: 'Es necesario ingresar un número de documento',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Escribe solo números (no incluyas puntos, comas o guiones)'
                                                } })}
                                        />                  
                                    </div>
                                </div>
                                <label className='mb-3'>Si deseas cambiar tu <span className='fst-italic'>"Tipo de documento"</span> o <span className='fst-italic'>"Número de documento"</span> de identidad debes <Link to='/contactanos' className='text_primary fw-bold'>contactarnos.</Link></label>
                                <div className="mb-3">
                                    <label className='ProfilePage__form-label'>Teléfono</label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        className="ProfilePage__select"
                                        rules={{ required: 'Es necesario ingresar un número de contacto' }}
                                        render={({ field }) => 
                                        <PhoneInput {...field}
                                        inputClass={`ProfilePage__input-phone 
                                                    ${errors.phone?"ProfilePage__input--error":""}`}
                                        specialLabel=''
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            placeholder: '999 999 999'
                                        }}
                                        country={'co'}
                                        disabled={isDisabled}
                                        />}
                                    />
                                    {errors.phone && (
                                        <span role="alert" className='ProfilePage__inputError'>
                                        {errors.phone.message}
                                        </span>
                                    )}                        
                                </div>
                                <div className="mb-3">
                                    <label className='ProfilePage__form-label'>Contraseña</label>
                                    <Link to={"#"} onClick={handleChangePassword}>
                                    <div className='col-12 ProfilePage__change-password'>
                                        Cambiar contraseña
                                    </div>
                                    </Link>                    
                                </div>
                                <button
                                    type="submit"
                                    className={`ProfilePage__btn-form`}
                                    disabled={data.loading}
                                >
                                    {!data.loading && (<span>Actualizar</span>)}
                                    {data.loading && (<><span> Procesando... </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
                                </button>
                                <ErrorMessage className='mb-1 auth__errorMessage--2lines' message={profileError}/>
                                <Toast {...toastProperties} closeCallback={afterCloseToast} position={{vertical:'bottom', horizontal: 'left'}} ></Toast>
                                <ModalConfirmUpdate handleCloseModal={handleCloseModal} showModalConfirmUpdate={showModalConfirmUpdate} handleConfirmUpdate={handleConfirmUpdate} handleSubmit={handleSubmit} />
                                <ModalChangePassword handleCloseModal={handleCloseModal}
                                 showModalChangePassword={showModalChangePassword} handleConfirmUpdate={handleConfirmUpdate} handleSubmit={handleSubmit}
                                 control={control}
                                 authToken={authToken}
                                 watch={watch}
                                 setToastProperties={setToastProperties}  />
                                <ModalWarningEmailNotChange handleCloseModal={handleCloseModal} showModalEmailNotChange={showModalEmailNotChange}/>
                            </form>
                        </div>
                    </div>
                    :
                    <></>
                    }
                    {
                        profileMenuSection[1]
                        ?
                        <ProfileSectionCoursesManager handleFetch={handleFetch} data={data} authToken={authToken} user={user}/>
                        :
                        <></>
                    }
                    {
                        profileMenuSection[2]
                            ?
                            <ProfileSectionPaymentsAndBills handleFetch={handleFetch} data={data} authToken={authToken} />
                            :
                            <></>
                    }
                    
                </div>
        <div></div>
    </>
  )
}
// Función menú opciones en página de perfil
const ProfileMenuSection = ({profileMenuSection, setProfileMenuSection, handleFetch, data, authToken})=>{
    const navigate = useNavigate();
    return(
        <div className='ProfilePage__menu-container ProfilePage__margin-50'>
                        <div className='ProfilePage__menu-margins'> 
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-11'>
                                    <div className={`row ${profileMenuSection[0]? 'ProfilePage__menu-item-active':'ProfilePage__menu-item'} `} onClick={()=>setProfileMenuSection([true, false, false])}>
                                        <div className='col-1 p-0 ProfilePage__menu-icon'>
                                            <img alt='check icon' src={landingImages('./icons/infocuenta.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px"}}/>
                                        </div>
                                        <div className='col-10  p-0 ProfilePage__menu-text'>
                                            Información de Cuenta
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-11'>
                                    <div className={`row ${profileMenuSection[1]? 'ProfilePage__menu-item-active':'ProfilePage__menu-item'} `} onClick={()=>setProfileMenuSection([false, true, false])}>
                                        <div className='col-1 p-0 ProfilePage__menu-icon'>
                                            <img alt='check icon' src={landingImages('./icons/miscursos.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px", marginRight: "5px"}}/>
                                        </div>
                                        <div className='col-10 p-0 ProfilePage__menu-text'>
                                            Cursos y Certificados
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-11'>
                                    <div className={`row ${profileMenuSection[2]? 'ProfilePage__menu-item-active':'ProfilePage__menu-item'} `} onClick={()=>setProfileMenuSection([false, false, true])}>
                                        <div className='col-1  p-0 ProfilePage__menu-icon'>
                                            <img alt='check icon' src={landingImages('./icons/pagos.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px", marginRight: "5px"}}/>
                                        </div>
                                        <div className='col-10 p-0 ProfilePage__menu-text'>
                                            Pagos y Facturas
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-11'>
                                    <div className={'row ProfilePage__menu-item'} onClick={()=>navigate('/cursos')}>
                                        <div className='col-1  p-0 ProfilePage__menu-icon'>
                                            <img alt='check icon' src={landingImages('./icons/catalogo.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px", marginRight: "5px"}}/>
                                        </div>
                                        <div className='col-10 p-0 ProfilePage__menu-text'>
                                            Catálogo de Cursos
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    )

}
// Función cursos inscritos
const ProfileSectionCoursesManager = ({handleFetch, data, authToken, user})=>{

    const [courseManageMenuView, setCourseManageMenuView  ] = useState([false, false, false])

    const [myCourses, setMyCourses] = useState([])

    useEffect(() => {
    
        const setCourses = (c_courses => () => {
            const hasToBegin = c_courses.some(c => c['user_study.progress']==0);
            const hasInProgress = c_courses.some(c => (c['user_study.progress']>0 && c['user_study.progress']<100)  ||
                                                        (c['user_study.progress']==100 && c['user_study.avgScore']<MINIMUM_CERT_SCORE));
            const hasCompleted = c_courses.some(c => c['user_study.progress']==100 && c['user_study.avgScore']>=MINIMUM_CERT_SCORE);
            if(hasToBegin) {
                setCourseManageMenuView ([true, false, false]);
            } else if(hasInProgress) {
                setCourseManageMenuView ([false, true, false]);
            } else if (hasCompleted) {
                setCourseManageMenuView ([false, false, true]);
            } else {
                setCourseManageMenuView ([true, false, false]);
            }
            return c_courses;
        })

      const response = handleFetch({
          url:'/courses/taken',
          method: 'GET',
          headers: {Authorization: `Bearer ${authToken}`} 
  
      })
      response
                  .then((r) => {
                     
                      setMyCourses(setCourses(r.data))
                      },
                      error => {
                          console.log(error.errors);
                          if(error.errors) {
      
                          }
                          
                      })
     
    }, [])

    return(
            <div className='ProfilePage__courses-manager-container'>
                <div className='row'>
                    <div className='col-12 col-md-10 col-lg-8 col-xl-7'> 
                        <div className='row ProfilePage__courses-manager-menu'>
                            <div className='col-4' onClick={()=>setCourseManageMenuView([true, false, false])}>
                                <span className={` ${courseManageMenuView[0]? 'menu-view-active': 'menu-view'} `}>
                                    Por iniciar
                                </span>
                            </div>
                            <div className='col-4' onClick={()=>setCourseManageMenuView([false, true, false])}>
                               <span className={` ${courseManageMenuView[1]? 'menu-view-active': 'menu-view'} `}>
                                   En progreso
                                </span> 
                            </div>
                            <div className='col-4' onClick={()=>setCourseManageMenuView([false, false, true])}>
                                <span className={` ${courseManageMenuView[2]? 'menu-view-active': 'menu-view'} `}>
                                    Certificados
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                    {data.loading
                    ?
                    <>
                        <Loading />
                    </>:
                    <>
                        {courseManageMenuView[0] ? <InProgressCourses myCourses={myCourses} newCoursesOnly={true} />: <></>}
                        {courseManageMenuView[1] ? <InProgressCourses myCourses={myCourses} />: <></>}
                        {courseManageMenuView[2] ? <FinishedCourses myCourses={myCourses} user={user}/>: <></>}
                    </>
                    }
                   
            </div>
    )
}
// Menú pagos y planes contratados
const ProfileSectionPaymentsAndBills = ({handleFetch, data, authToken})=>{

    const [paymentsAndBillsMenuView, setPaymentsAndBillsMenuView  ] = useState([true, false, false])
    return(
        <div className='ProfilePage__courses-manager-container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='row ProfilePage__courses-manager-menu'>
                        <div className='col-4' onClick={()=>setPaymentsAndBillsMenuView([true, false, false])}>
                                <span className={` ${paymentsAndBillsMenuView[0]? 'menu-view-active': 'menu-view'} `}>
                                    Plan actual
                                </span>
                        </div>
                        <div className='col-4' onClick={()=>setPaymentsAndBillsMenuView([false, true, false])}>
                               <span className={` ${paymentsAndBillsMenuView[1]? 'menu-view-active': 'menu-view'} `}>
                                   Histórico de pagos
                                </span>
                        </div>
                        <div className='col-4' onClick={()=>setPaymentsAndBillsMenuView([false, false, true])}>
                               <span className={` ${paymentsAndBillsMenuView[2]? 'menu-view-active': 'menu-view'} `}>
                                    Cursos adquiridos
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            {data.loading
                ?
                <>
                    <Loading />
                </>:
                <>
                    {paymentsAndBillsMenuView[0] ? <PaymentsAndBillsData all={false}/>: <></>}
                    {paymentsAndBillsMenuView[1] ? <PaymentsAndBillsData all={true} />: <></>}
                    {paymentsAndBillsMenuView[2] ? <IndividualCoursesPurchasedData /> : <></>}
                </>
            }

        </div>
    )
}
// Función cursos individuales comprados
const IndividualCoursesPurchasedData = ({all = false}) =>{
    const [coursesPurchased, setCoursesPurchased] = useState([]);
    const {authToken, getSessionUser} = useAuthToken();
    const [data,courseTaker] = useCourseTaker();
    const [errorDialog, setErrorDialog] = useState(false);
    const [fetchData, handleFetch] = useFetch();
    // useEffect para consultar pagos realizados por el usuario
    useEffect(() => {
        const response = handleFetch({
            url:`courses/coursespurchased`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                setCoursesPurchased(r)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }

                })

    }, [])
    const onCourseRedirection = (course) => {
        console.log("coursetaken",course)
        courseTaker(course, () => setErrorDialog(true) );
    }
    return(
        <>
            <div className='row'>
                <div className='col-12'>
                    {coursesPurchased.length===0 &&
                        <div className="col mx-sm-5">
                            <div className="text-center text_primary my-5" >
                                <i className="fa-solid fa-book fa-4x mb-3"></i>
                                <p style={{fontSize: "20px"}}>No has comprado cursos todavía <br /><Link className='ProfilePage__link-to-course' to='/cursos' style={{fontWeight: "600"}}>Visita nuestro catálogo</Link></p>
                            </div>
                        </div>
                    }
                    {coursesPurchased.map((course, index)=>
                        <div key={index} className='d-block d-md-flex ProfilePage__course-in-progress-row'>
                            <div className='d-flex justify-content-center me-md-3' style={{marginBottom: '15px'}}>
                                <img className='ProfilePage__in-progress-course-image' src={course.course.imageUrl} onClick={()=>{onCourseRedirection(course.course)}}/>
                            </div>
                            <div className='col-12 col-md-7 col-lg-7 col-xl-7'>
                                <div className='row' onClick={()=>{onCourseRedirection(course.course)}}>
                                    <span className='ProfilePage__course-title'>{course.course.title}</span>
                                </div>
                                <div className='row'>
                                    <span className='ProfilePage__course-instructor-name'>Por {course.course.instructorData.name}</span>
                                </div>
                                <div className='row mt-2'>
                                    <span className='ProfilePage__course-instructor-name'>Precio: {course.coursePrice}</span>
                                </div>
                                <div className='row mt-2'>
                                    <span className='ProfilePage__course-instructor-name'>Codigo de pago: {course.approvalCode}</span>
                                </div>
                            </div>
                        </div>              
                    )}
                </div>
            </div>
        </>
    )
}
// Función pagos y planes contratados
const PaymentsAndBillsData = ({all = false}) =>{
    const [history, setHistory] = useState([]);
    const {authToken, getSessionUser} = useAuthToken();
    const [fetchData, handleFetch] = useFetch();
    // useEffect para consultar pagos realizados por el usuario
    useEffect(() => {
        const response = handleFetch({
            url:`payments`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setHistory(r)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }

                })

    }, [])
    let today = (new Date());
    // Función para mostrar estado de cada plan contratado
    const statusCalcule = (dateStart,dateEnd) => {
        const statusCode = getPlanStatus(dateStart,dateEnd);
        let status = '';
        switch(statusCode) {
            case CONSTANTS.PLAN_STATUS_ACTIVE:
                status = <li className='ProfilePage__data-payments-active'>Activo</li>;
                break;
            case CONSTANTS.PLAN_STATUS_PENDING:
                status = <li className='ProfilePage__data-payments-pending'>Pendiente</li>;
                break;
            case CONSTANTS.PLAN_STATUS_EXPIRED:
                status = <li className='ProfilePage__data-payments-inactive'>Vencido</li>;
                break
        }
        return status;
    }
    // Función para ordenar planes según fecha de contratación
    function SortArray(p1, p2){
        const statusP1 = getPlanStatus(p1.startsAt, p1.endsAt);
        const statusP2 = getPlanStatus(p2.startsAt, p2.endsAt);
        if(statusP1!=statusP2) return statusP1-statusP2;
        return new Date(p1.startsAt) - new Date (p2.startsAt);
    }
    var historytest = history.sort(SortArray);
    let last = history.length-1;
    return(
        <>
            { history.length !== 0 ?
                all ?
                    historytest.map((data, index)=>{
                        let statusView = statusCalcule(data?.startsAt,data?.endsAt);
                        let payment = moment.utc(data?.createdAt).format('DD/MM/YYYY');
                        let dateInit = moment.utc(data?.startsAt).format('DD/MM/YYYY');
                        let dateEnd = moment.utc(data?.endsAt).format('DD/MM/YYYY');
                        let prefixInit = new Date(data?.startsAt).getTime() < new Date().getTime() ? 'Inició' : 'Inicia';
                        let prefixEnd = new Date(data?.endsAt).getTime() > new Date().getTime() ? 'Vence' : 'Venció';
                        return(
                        <div className='row' key={index}>
                            <div className='col-12 justify-content-center ProfilePage__content-data-payments'>
                                <div className='col-11 ProfilePage__data-payments mb-3'>
                                    <div className='col-12 col-sm-12 col-lg-8 ProfilePage__data-payments-flex'>
                                        <div className='ProfilePage__data-payments-left fw-bold'>{data?.plan['title']}</div>
                                        <div className='ProfilePage__data-payments-right ProfilePage__data-payments-status'>{statusView}</div>
                                        <div className='ProfilePage__data-payments-left'>Comprado el {payment}</div>
                                        <div className='ProfilePage__data-payments-right'>Costo <AmountFormat
                                            name='paidPrice'
                                            value={data?.paidPrice}
                                            displayType='text'
                                            currency='COP'
                                        /></div>
                                        <div className='ProfilePage__data-payments-left mt-1'>{prefixInit} el {dateInit}</div>
                                        <div className='ProfilePage__data-payments-right mt-1'>{prefixEnd} el {dateEnd}</div>
                                    </div>
                                </div>
                            </div>
                        </div> )
                    }) :
                    history.map((data)=>{
                        let statusView = statusCalcule(data?.startsAt,data?.endsAt);
                        let payment = moment.utc(data?.createdAt).format('DD/MM/YYYY');
                        let dateInit = moment.utc(data?.startsAt).format('DD/MM/YYYY');
                        let dateEnd = moment.utc(data?.endsAt).format('DD/MM/YYYY');
                        return(
                            (new Date(data?.endsAt).getTime() >= today && new Date(data?.startsAt).getTime() <= today) &&
                            <><div className='row'>
                                <div className='col-12 justify-content-center ProfilePage__content-data-payments'>
                                    <div className='col-11 ProfilePage__data-payments mb-3'>
                                        <div className='col-12 col-sm-12 col-lg-8 ProfilePage__data-payments-flex'>
                                            <div className='ProfilePage__data-payments-left fw-bold'>{data?.plan['title']}</div>
                                            <div className='ProfilePage__data-payments-right ProfilePage__data-payments-status'>{statusView}</div>
                                            <div className='ProfilePage__data-payments-left'>Comprado el {payment}</div>
                                            <div className='ProfilePage__data-payments-right'>Costo <AmountFormat
                                                name='paidPrice'
                                                value={data?.paidPrice}
                                                displayType='text'
                                                currency='COP'
                                            /></div>
                                            <div className='ProfilePage__data-payments-left mt-1'>Inicia el {dateInit}</div>
                                            <div className='ProfilePage__data-payments-right mt-1'>Vence el {dateEnd}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { (!data?.canCertificate) && 
                                <div className='row'>
                                    <div className='col-12 justify-content-center ProfilePage__alert-no-certifications'>
                                        <div className='col-11 ProfilePage__data-alert-no-certifications mb-3'>
                                            <i className="fa-solid fa-triangle-exclamation"></i> Este tipo de plan no incluye certificaciones.
                                        </div>
                                    </div>
                                </div>
                            }
                            </>
                            
                            
                            )
                    })
                :
                <div className='row'>
                    <div className='col-12 justify-content-center ProfilePage__content-data-payments'>
                        <div className='col-11 text-center ProfilePage__data-payments mb-3'>
                            <strong>No existen registros</strong>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

// Función para mostrar mensaje de error 
const ErrorMessage = (props) => {
    return (
        <div className={`auth__errorMessage ProfilePage__info-error ${props.className}`}>
            <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
            </span>
        </div>
    )
}
// Función para mostrar modal de datos actualizados correctamente
const ModalUpdated = ({handleCloseModal, showModalUpdated}) =>{
    return(
        <Modal
            open={showModalUpdated}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
                <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                    <div className='row justify-content-center align-items-center ProfilePage__outline-none'>
                        <div className='col-4 d-flex justify-content-center'>
                            <img src={landingImages('./thumb-up-icon.png')} style={{width: "120%"}} alt="thumb up" />
                        </div>
                        <div className='col-7'>
                            ¡Tus datos se   <br />
                            han actualizado <br />
                            correctamente!
                        </div>
                        <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                            <button 
                                className='ProfilePage__modal-button-OK'
                                onClick={handleCloseModal}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
        </Modal>
    )

}
// Función para mostrar modal con advertencia de actualización de datos
const ModalConfirmUpdate = ({showModalConfirmUpdate, handleCloseModal, handleConfirmUpdate, handleSubmit})=>{
    return(
            <Modal
            open={showModalConfirmUpdate}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./icons/lock-icon.svg')} style={{width: "58px", marginTop: "47px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Advertencia</div>
                        <p className='ProfilePage__modal-text-description' >
                            Al hacer este cambio, serás redirigido para que ingreses una validación.<br></br>¿Quieres continuar?
                        </p>
                        <div className='' style={{marginTop: "20px", textAlign: "center"}}>
                            <button 
                                className='ProfilePage__modal-button-accept'
                                onClick={handleSubmit(handleConfirmUpdate)}
                            >
                                Aceptar
                            </button>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "40px", textAlign: "center"}}>

                            <button 
                                className='ProfilePage__modal-button-cancel'
                                onClick={handleCloseModal}
                                >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>                                        
        </Modal>
    )
}
// Función para mostrar Modal que permite cambio de contraseña
const ModalChangePassword = ({showModalChangePassword, handleCloseModal, handleSubmit, authToken, control, watch, setToastProperties})=>{
    const [data, handleFetch] = useFetch();

    const [changePasswordError, setChangePasswordError] = useState('');
    const [confirmPasswordMatches, setConfirmPasswordMatches] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const setValidPassword = (isValid) =>  {
        setPasswordIsValid(isValid);
    }

    const password=watch('password','');
    const password2=watch('password2','');

    useEffect(()=> {
        setConfirmPasswordMatches(password===password2);
    },[password, password2]);
    // Handle para cambio de contraseña
    const handleChangePassword = (e) => {
        setChangePasswordError('');
        if(passwordIsValid && confirmPasswordMatches) {

        const response = handleFetch({
            url:'users/update',
            method: 'POST', 
            data: {
                email:e.userEmail,
                lastname:e.lastName,
                name:e.firstName,
                phone: e.phone.replace('+', ''), 
                password: e.password
            },
            headers: {Authorization: `Bearer ${authToken}`} 

        });
        response
            .then((r) => {
                setToastProperties({
                    show:true,
                    type:'success',
                    title: '¡Tu contraseña fue cambiada correctamente!',
                    message: 'Recuerda mantenerla en secreto por medidas de seguridad',
                    position: {horizontal:'left'},
                })
                handleCloseModal()
            },
                error => {
                    if(error.errors) {
                        setChangePasswordError(error.errors[0].message)
                    }        
            });
        }
    }
    const handleCloseModalAux = () => {
        handleCloseModal()
    }
    return(
            <Modal
            open={showModalChangePassword}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='ProfilePage__modal-info-container'>
                    <form onSubmit={ handleSubmit((e) => handleChangePassword(e)) }>
                        <div className="mt-3">
                            <label className='auth__form-label'>Nueva contraseña <span className='auth__required-input'>*</span></label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                                render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                                    return (
                                    <PwdEye 
                                        className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                        placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        name={name}
                                        error={invalid}
                                    >
                                    </PwdEye>)
                                    }
                                }
                            />
                            <div className="ProfilePage__conditions-password">
                                <PasswordValidator 
                                value={password} 
                                checkCriteria={setValidPassword}
                                ></PasswordValidator>
                                <div className={`${passwordIsValid?"d-none":""}`}>
                                    <ErrorMessage message={!passwordIsValid?CONSTANTS.MESSAGE_PASSWORD_IVALID:''}></ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className='auth__form-label'>Repite tu contraseña <span className='auth__required-input'>*</span></label>
                            <Controller
                                name="password2"
                                control={control}
                                rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                                render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                                    return (
                                    <PwdEye 
                                        className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                        placeholder={CONSTANTS.LABEL_PASSWORD_CONFIRM_PLACEHOLDER}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        name={name}
                                        error={invalid}
                                    >
                                    </PwdEye>)
                                    }
                                }
                            />
                            <div className={`${confirmPasswordMatches?"d-none":""}`}>
                                <ErrorMessage message={!confirmPasswordMatches?CONSTANTS.MESSAGE_PASSWORD_CONFIRM_DOESNT_MATCH:''}></ErrorMessage>
                            </div>
                            
                        </div>
                        <div className={`${!changePasswordError?"d-none":""}`}>
                            <ErrorMessage className='mb-1 text-center auth__errorMessage--2lines' message={changePasswordError}/> 
                        </div>
                        <div className='' style={{textAlign: "center"}}>
                            <button 
                                className='ProfilePage__modal-button-accept'
                                onClick={handleSubmit(handleChangePassword)}
                            >
                                {!data.loading && (<span>Cambiar contraseña</span>)}
                                {data.loading && (<><span> Procesando... </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
                            </button>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "40px", textAlign: "center"}}>
                            <button className='ProfilePage__modal-button-cancel' onClick={handleCloseModalAux} >
                                Cancelar
                            </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>                                        
        </Modal>
    )
}
// Función para mostrar modal en caso cambio de correo electrónico de comunidad eanista
const ModalWarningEmailNotChange = ({showModalEmailNotChange, handleCloseModal}) =>{
    
    return(
        <Modal
            open={showModalEmailNotChange}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./icons/lock-icon.svg')} style={{width: "58px", marginTop: "47px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Advertencia</div>
                        <p className='ProfilePage__modal-text-description'>
                            Recuerda que si eliges cambiar tu correo por uno de <span className='ProfilePage__color-green fw-bold'>comunidad eanista</span>, este cambio sólo será reversible comunicándote con nuestro <span className='ProfilePage__color-green fw-bold'>equipo de soporte</span>
                        </p>
                        <div style={{marginTop: "10px", textAlign: "center"}}>
                            <button 
                                className='ProfilePage__modal-button-accept'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "40px", textAlign: "center"}}>

                            <button
                                className='ProfilePage__modal-button-cancel'
                                onClick={handleCloseModal}
                                >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{minHeight: "300px"}}>
          <div className='row text-center position-absolute justify-content-center'>
              <img alt='Loader' src={loadingImage}/>
          </div>
        </div>
    );
}
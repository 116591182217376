import React from 'react'
import { Link } from 'react-router-dom';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de pie de página
 * @function Footer
 */
export const Footer = () => {
  return (
    <div>
                <div className='footer__footer-1'>
            <div className='container'>
                <div className='row align-center'>
                    <div className='col-12 py-5'>
                        <div className='row justify-content-center text-start'>
                            <div className='col-12 col-md-3'>
                                <p className='footer__text-color-white'>
                                    ©UNIVERSIDAD Ean: <span className='footer__text-color-green'>SNIES 2812 </span>
                                    Personería Jurídica <span className='footer__text-color-green'>Res. no. 2898</span> del
                                    <span className='footer__text-color-green'> Minjusticia</span> - 16/05/69
                                    Vigilada <span className='footer__text-color-green'>Mineducación</span>
                                </p>
                            </div>
                            <div className='col-12 col-md-9 footer__text-color-dark-grey'>
                                Decreto 2550/17 | PQRS | Reglamento docente - 27/10/2005 | Reglamento estudiantil - 21/07/16 | Reglamento estudiantil -17/10/13 | Resolución 00107 - 14/11/2018 | Tarifas y Precios | Estatutos universidad Ean | Reglamento de bienestar | Reglamento docente - 18/08/2016
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer__footer-2'>

            <div className='container'>
                <div className='row align-center'>
                    <div className='col-12 py-5'>
                        <div className='row justify-content-center text-start'>
                            <div className='col-lg-3 col-12'>
                                <div className='row justify-content-evenly justify-content-md-start justify-content-lg-evenly mb-4 mb-lg-auto'>
                                    <div className='col-lg-4 col-md-2 col-4'>
                                        <img alt='Logo EAN vertical' className='img-fluid' src={landingImages('./ean-logo-vertical.png')} />
                                    </div>
                                    <div className='col-lg-8 col-8'>
                                        <p className='footer__text-color-white'>
                                            CON ACREDITACIÓN INSTITUCIONAL DE ALTA CALIDAD<br />
                                            Res. No 29499 del Mineducación
                                            29/12/17, Vigencia 28/12/21
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 mb-3 mb-lg-auto'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className='footer__text-color-white'>
                                        <a href="https://goo.gl/maps/ZVzPGKs2Ah82" target="_blank" title="El Nogal"><span className='footer__text-color-white'>El Nogal: Calle 79 # 11 - 45</span></a> <a href="https://goo.gl/maps/UidG7Q2xE4H2" target="_blank" title="Avenida Chile"><span className='footer__text-color-white'>Av. Chile: Calle 71 # 9 - 84</span></a> Bogotá D.C., Cundinamarca, Colombia, Suramérica.
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className='footer__text-color-white'>
                                            Línea gratuita nacional: <a href="tel:018000931000" title="Línea gratuita nacional"><span className='footer__text-color-green'>01 8000 93 1000</span></a> Centro de contacto en Bogotá: <a href="tel:6015936161" target="_blank" title="Centro de contacto en Bogotá - Línea 1"><span className='footer__text-color-green'>+(57-1) 593 6161</span></a> <a href="tel:6015936464" target="_blank" title="Centro de contacto en Bogotá - Línea 2"><span className='footer__text-color-green'>+(57-1) 593 6464</span></a> E-mail: <a href="mailto:informacion@universidadean.edu.co" title="Correo electrónico"><span className='footer__text-color-green'>informacion@universidadean.edu.co</span></a>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className='footer__text-color-green'>
                                        <a href="https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad" title="Aviso de privacidad"><span className='footer__text-color-green'>Aviso de privacidad</span></a> <span className='footer__text-color-white'>|</span> <a href="https://universidadean.edu.co/sites/default/files/institucion/acuerdos/Acuerdo-No-035-2021-SG.pdf" target="_blank" title="Política de Tratamiento de Datos Personales"><span className='footer__text-color-green'>Política de Tratamiento de Datos Personales</span></a> <span className='footer__text-color-white'>|</span> <a href="https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/manual-de-uso-de-cookies-y-otras-tecnologias-de-la-universidad-ean" title="Manual de Cookies u otras Tecnologías"><span className='footer__text-color-green'>Manual de Cookies u otras Tecnologías</span></a> <span className='footer__text-color-white'>|</span> <a href="https://universidadean.edu.co/sites/default/files/2022-07/Manual%20de%20Seguridad%20de%20la%20Informaci%C3%B3n%20%28v6%29.pdf" target="_blank" title="Manual de Seguridad de la Información"><span className='footer__text-color-green'>Manual de Seguridad de la Información</span></a> <span className='footer__text-color-white'>|</span> <a href="https://universidadean.edu.co/sites/default/files/institucion/acuerdos/Directrices_de_uso_de_las_Plataformas_Digitales.pdf" target="_blank" title="Directrices de uso de las Plataformas Digitales"><span className='footer__text-color-green'>Directrices de uso de las Plataformas Digitales</span></a> <span className='footer__text-color-white'>|</span>  <a href="https://eanx.co/terminos-condiciones.pdf" target="_blank" title="Términos y condiciones de uso EanX"><span className='footer__text-color-green'>Términos y condiciones de uso EanX</span></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-12 footer__text-color-white'>
                                                Contáctanos
                                            </div>
                                        </div>
                                        <div className='row justify-content-center'>
                                            <div className='col-12 d-flex justify-content-md-start justify-content-center'>

                                                <a href="https://www.facebook.com/EanXEducacion" target="_blank"><img alt='Facebook Icon' src={landingImages('./icons/social-media/facebook-icon.svg')} style={{width: "25px", height: "auto", margin: "7px"}} /></a>
                                                <a href="https://twitter.com/eanxeducacion" target="_blank"><img alt='Twitter Icon' src={landingImages('./icons/social-media/twitter-icon.svg')} style={{width: "25px", height: "auto", margin: "7px"}} /></a>
                                                <a href="https://www.instagram.com/eanxeducacion/" target="_blank"><img alt='Instagram Icon' src={landingImages('./icons/social-media/instagram-icon.svg')} style={{width: "25px", height: "auto", margin: "7px"}} /></a>
                                                <a href="https://www.linkedin.com/showcase/eanxeducacion/" target="_blank"><img alt='Linkedin Icon' src={landingImages('./icons/social-media/linkedin-icon.svg')} style={{width: "25px", height: "auto", margin: "7px"}} /></a>
                                                <a href="https://www.youtube.com/channel/UCA2Za0b6wvAlCCwn1ttc0Pw" target="_blank"><img alt='YouTube Icon' src={landingImages('./icons/social-media/youtube-icon.svg')} style={{width: "25px", height: "auto", margin: "7px"}} /></a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 footer__text-color-white'>
                                                Descarga nuestra app en
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>

                                                <a href="https://play.google.com/store/apps/details?id=co.ean.app.universidadean" target="_blank"><img alt='Google Play Icon' src={landingImages('./icons/social-media/google-play-icon.svg')} style={{width: "100px", height: "auto", margin: "7px"}} /></a>
                                                <a href="https://apps.apple.com/co/app/universidad-ean/id1328447773" target="_blank"><img alt='Apple Store Icon' src={landingImages('./icons/social-media/apple-store-icon.svg')} style={{width: "100px", height: "auto", margin: "7px"}} /></a>
                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SimpleFooter></SimpleFooter>
    </div>
  )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de pie de página simple
 * @function SimpleFooter
 */
let today = new Date();
let year = today.getFullYear();
export const SimpleFooter = () =>{
    return(
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center my-4'>
                <p className='footer__text-rights mx-3'>{year} Todos los derechos reservados</p> <Link to="/" ><img alt='Ean UNIVERSIDAD' src={landingImages('./logo-eanx.png')} style={{width: "46px", height: "auto"}} /></Link>
            </div>
        </div>
    )
}
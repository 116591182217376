import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFetch } from "./useFetch";
import { apiConfig } from '../api/apiConfig';
import { useUserPlan } from "./useUserPlan";
import moment from 'moment';
// Hook para gestionar planes
export const useGetPlan = () => {
    const [data, handleFetch] = useFetch();
    const [mpPreference, setMpPreference] = useState('null');
    const {saveUserHasPlan} = useUserPlan();
    const navigate = useNavigate();
    // Handle envío de información a mercagopago
    const mpPayment = (data, recurring=false) => {
        const action = recurring?'subscription':'preference'       
        handleFetch({
          url: `payments/mp_${action}`,
          method: 'POST',
          data
        }).then(p => {
            window.location.replace(p.init_point);
        })
    }
    /*const mpPayment = async (data, recurring=false) => {
        let ip = ""
        await fetch('https://api64.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
            ip = data.ip
        })
        .catch(error => console.error('Error al obtener la IP:', error));
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 30);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}-05:00`;
        const senddata = { "plan":
            {
            "name":data.plan.name,
            "currency":"COP",
            "price":data.plan.price
          },
          "reference":`${year}${month}${day}${data.plan.id}${data.user.id}`,
          "ip": ip,
          "userAgent": window.navigator.userAgent,
          "expiration": `${formattedDate}`,
          "buyer": {
            "name": data.user.name,
            "surname": data.user.lastname,
            "documentType": data.user.documentType,
            "document": data.user.document,
            "email": data.user.email,
            "mobile": data.user.phone
            }
        }
        let requestOptions = {
            method: 'POST',
            headers: Object.assign({},apiConfig.header, {}),
            body: JSON.stringify(senddata)
        };
        await fetch( `${ apiConfig.baseUrl }payments/placetopay`,  requestOptions)
        .then( async resp => {
            const response = await resp.json();
            window.P.init(response.processUrl);
            window.P.on('response', function (response) {
                let promise = new Promise(resolve => {
                    handleFetch({
                        url: `payments/webhookptp`,
                        method: 'POST',
                        data: {
                            requestId: response.requestId
                        }
                    })
                    .then((r) => {
                        const price = r.request && r.request.payment && r.request.payment.amount && r.request.payment.amount.total ? r.request.payment.amount.total : undefined;
                        const currency = r.request && r.request.payment && r.request.payment.amount && r.request.payment.amount.currency ? r.request.payment.amount.currency : undefined;
                        const authorization = r.payment && r.payment[0] && r.payment[0].authorization ? r.payment[0].authorization : undefined;
                        const reference = r.request && r.request.payment ? r.request.payment.reference : undefined;
                        resolve({
                            status: r.status.status,
                            date: moment.utc(r.status.date).format('DD/MM/YYYY'),
                            price: price,
                            currency: currency,
                            authorization: authorization,
                            reference: reference
                        });
                    },()=>resolve(false))
                })
                promise.then((value) => {
                    if(response.status.status === "APPROVED"){
                        navigate(`/cuenta-creada?&status=approved&external_reference=${data.user.id}-${data.plan.id}&payment_id=${response.reference}&merchant_order_id=${response.reference}&requestId=${response.requestId}`,{state: {
                            plan: data.plan, user: data.user, toastProperties: {
                            show:true,
                            type:'success',
                            title: 'Se ha completado la operación exitosamente',
                            message: `<b>Datos de la operación</b>
                            <br><b>Estado</b>: ${value.status || '-'} 
                            <br><b>Fecha</b>: ${value.date} 
                            <br><b>Monto</b>: ${value.price || '-'} 
                            <br><b>Moneda</b>: ${value.currency || '-'} 
                            <br><b>Autorización/CUS</b>: ${value.authorization || '-'} 
                            <br><b>Referencia de pago</b>: ${value.reference || '-'}`,
                            duration: 25000,
                            position: { horizontal: 'right', vertical: 'top' }
                        }}});
                    }else if(response.status.status === "PENDING"){
                        associateErrorPlan(data.user,data.plan,1,response.requestId)
                        navigate('/',{state: {
                            toastProperties:{
                                show:true,
                                type:'pending',
                                title: 'La petición se encuentra pendiente',
                                message: `El pago está en proceso de revisión, serás notificado al finalizar la operación.<br><br>
                                <b>Datos de la operación</b>
                                <br><b>Estado</b>: ${value.status || '-'} 
                            <br><b>Fecha</b>: ${value.date} 
                            <br><b>Monto</b>: ${value.price || '-'} 
                            <br><b>Moneda</b>: ${value.currency || '-'} 
                            <br><b>Autorización/CUS</b>: ${value.authorization || '-'} 
                            <br><b>Referencia de pago</b>: ${value.reference || '-'}`,
                                duration: 25000,
                                position: { horizontal: 'right', vertical: 'top' },
                            }
                        }});
                    }else if(response.status.status === "REJECTED"){
                        associateErrorPlan(data.user,data.plan,0,response.requestId)
                        navigate('/',{state: {
                            toastProperties:{
                                show:true,
                                type:'error',
                                title: 'Ha ocurrido un error realizando la operación',
                                message: `No pudo completarse la operación, por favor intenta nuevamente.<br><br>
                                <b>Datos de la operación</b>
                                <br><b>Estado</b>: ${value.status || '-'} 
                                <br><b>Fecha</b>: ${value.date} 
                                <br><b>Monto</b>: ${value.price || '-'} 
                                <br><b>Moneda</b>: ${value.currency || '-'} 
                                <br><b>Autorización/CUS</b>: ${value.authorization || '-'} 
                                <br><b>Referencia de pago</b>: ${value.reference || '-'}`,
                                duration: 25000,
                                position: { horizontal: 'right', vertical: 'top' },
                            }
                        }});
                    }
                });
            });
        })
        .catch (error => {
            return Promise.reject(error)
        })
    };*/
    // Handle para asignar plan a usuario, recibe por parámetro id de usuario, id de plan y código si completa operación
    const associatePlan = (user,plan,code=null) => {
        return new Promise((resolve) => {
            let planAssociated='assigned';
            handleFetch({
                url: 'users/plans',
                method: 'POST',
                data: {
                    userId: user.id,
                    planId: plan.id,
                    approvalCode: code,
                }
            })
            .then (plan => {
                saveUserHasPlan(plan);
            })
            .catch(e => {planAssociated='not_assigned'})
            .finally (() => {
                resolve(planAssociated);
            })
        })
    }
    // Handle para asignar plan a usuario, recibe por parámetro id de usuario, id de plan y código si completa operación
    const associateErrorPlan = (user,plan,errorType,requestId,companyId=1) => {
        return new Promise((resolve) => {
            let planAssociated='not_assigned';
            handleFetch({
                url: 'users/plans/users_plans_review',
                method: 'POST',
                data: {
                    userId: user.id,
                    planId: plan.id,
                    errorType,
                    requestId,
                    companyId
                }
            })
            .then (plan => {
                resolve(planAssociated);
            })
            .catch(e => {planAssociated='not_assigned'})
            .finally (() => {
                resolve(planAssociated);
            })
        })
    }
    // Función para asociar plan según condición, recibe id usuario, id plan y pago recurrente
    const getPlan =  (user, plan, recurrent, fromRegister) => {
        let today = new Date();
        let day = today.getDate()<10 ? `0${today.getDate()}` : today.getDate();
        let month = today.getMonth()<10 ? `0${today.getMonth()+1}` : today.getMonth() + 1;
        let year = today.getFullYear();
        return new Promise((resolve) => {
            if(plan.price==0) {
                associatePlan(user,plan,`Plan gratis ${day}/${month}/${year}`)
                .then(pa => resolve(pa));
            } else {
                const data = {
                    plan: {
                        ...plan,
                        returnUrl: apiConfig.mercadoPago.returnUrl,
                        returnUrlBack: apiConfig.mercadoPago.returnUrlBack,
                    },
                    user
                  }
                mpPayment(data,recurrent);
                resolve('purchase');
            }
        });
    }

    return {getPlan, associatePlan, mpPreference};
}
import { useFetch } from "./useFetch";

// Hook para gestionar cupones de descuento
export const useAssociateDiscount = () => {
    const [data, handleFetch] = useFetch();
    // Handle para almacenar cupón de descuento utilizado
    // se recibe por parámetro id de usuario, id de plan o curso, id de cupón y precio pagado
    const associateDiscount = (user,course,plan,discount,payPrice) => {
        return new Promise((resolve) => {
            handleFetch({
                url: 'payments/discountuse',
                method: 'POST',
                data: {
                    userId: user.id,
                    couponId: discount.id,
                    courseId: course?.courseId || course?.id || null,
                    planId: plan?.id || null,
                    paidPrice: payPrice
                }
            })
            .then (disc => {
                resolve(disc);
            })
            .catch(e => resolve(null))
        })
    }

    return {associateDiscount};
}
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useGetPlan } from '../../hooks/useGetPlan';
import { createSplit } from '../controls/UIControls';

const landingImages = require.context('../../assets/images', true);

export const PlanCard = ({button_description,
                            button_color,
                            description,
                            duration,
                            durationText,
                            benefits,
                            name,
                            price,
                            plan_price,
                            plan_type,
                            top,
                            monthly_price,
                            plan_duration_period,
                            plan_duration,
                            isPlanSelected,
                            index,
                            onCardEnter,
                            resetState,
                            planId,
                            hasPlan,
                            maxHeight,
                            maxHeightBene
                        }) => {

    const { isLogged, getSessionUser } = useAuthToken();

    const[userHasPlan, setUserHasPlan] = useState(true);

    const logged = isLogged();
    const {getPlan} = useGetPlan();

    useEffect(() => {
        if (logged) {
            setUserHasPlan(hasPlan);
        }
    },[])


    const activateDataLayer = () =>{
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [{
            item_name: name,
            item_id: planId,
            location_id: "Online Store",
            price: plan_price,
            item_brand: "eanX",
            quantity: 1
            }]
        }
        });
    }
    const actionButtonProps =
                        (!logged)?{}:{
                                onClick: (e) => {
                                    const user = getSessionUser();
                                    e.preventDefault();
                                    getPlan(user,{id:planId, name, price: plan_price}, false)
                                }
                            }
    return (
        <div className={`col-10 col-sm-5 col-lg-4 px-2`} onMouseLeave={()=>resetState()} onMouseEnter={()=>onCardEnter(index)}>
            <div className={`row mx-2 mt-2 ${top ? 'plan__container-height-recommended' : 'plan__container-height'}`}  style={top ? (maxHeight > 250 || maxHeightBene > 7) ? {height: 510+(maxHeightBene*27)} : {height: (maxHeight)+(maxHeightBene*35)} : {height: 370+(maxHeightBene*42)}}>
                <div className={`col-12 p-3 plan__container ${isPlanSelected? "plan__color-active": ""} ${top? "plan__border": ""}`}>
                    {top
                    &&
                    <div className='row plan__suggested justify-content-center text-center ms-5'>
                        <div className='col-12 plan__suggested-text'>
                            <img alt='check icon' className='plan__suggested-icon' src={landingImages('./icons/crown-icon.svg')}  style={{maxWidth: "15px", minWidth: "15px", marginRight: "8px"}}/>
                             Recomendado
                        </div>
                    </div>} 
                    <div className={`row mt-2 text-start ${top ? 'plan__text-container__recommended' : 'plan__text-container'}`} style={top ? (maxHeight > 350) ? {height: maxHeight+50} : {height: (maxHeight*1.22)} : {height: 240+(maxHeight/3.5)} }>
                        <div className='col-12'>
                                
                            <p className={`plan__name ${top ? 'mt-3' : ''}`}>{name}</p>
                            {
                                monthly_price 
                                ?<p className='plan__price'>{monthly_price}<span className='plan__duration'></span></p>
                                :<p className='plan__price'>{price}<span className='plan__duration'></span></p>
                            }
                            {
                                /*!price.includes('$0') &&
                                <div>
                                    <p className='plan__total-price'>Un pago {durationText? durationText :duration.includes('1 ')?"cada "+duration.replace('1 ', ''): "cada "+duration } de {price}.</p>
                                </div>*/
                                !price.includes('$0') && durationText &&
                                <div>
                                    <p className='plan__total-price'>{durationText}</p>
                                </div>
                            }
                            <p className='plan__description'>{description}</p>
                        </div>
                    </div>
                    
                        <div className='row justify-content-center text-center mb-4'>
                            <Link to={`/planes/`+createSplit(name)} onClick={activateDataLayer}
                                    state={{plan:{id:planId, name, price: plan_price, duration: plan_duration, durationPeriod:plan_duration_period, type: plan_type}}}
                                    {...actionButtonProps}>
                                <div className={`col-12 ${(button_color === "Green")?'plan__button-free':'plan__button-pay'} `}>
                                {button_description}
                                </div>
                            </Link>
                        </div>
                        <div className='row justify-content-center text-start mb-1 align-items-'>
                                    <div className='col-2 col-sm-2 p-sm-auto col-lg-1'>
                                    <i className="fa-regular fa-circle-check plan__icon-check"></i>
                                    </div>
                                    <div className='col-9 col-sm-9 col-lg-10 plan__items-text ps-0'>
                                        <p style={{marginBottom: "0px"}}>Duración: {duration}</p>
                                    </div>
                        </div>
                        {
                            benefits.map((b) =>{
                                const icon=b.available?'check':'xmark';
                                return (
                                    <div className='row justify-content-center text-start mb-1' key={b.id}>
                                    <div className='col-2 col-sm-2 p-sm-auto col-lg-1'>
                                    <i className={`fa-regular fa-circle-${icon} plan__icon-${icon}`}></i>
                                    </div>
                                    <div className='col-9 col-sm-9 col-lg-10 plan__items-text ps-0'>
                                        <p style={{marginBottom: "0px"}}>{b.title}</p>
                                    </div>
                                </div>);
                        })
                        }

                </div>
            
             </div>
            
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { apiConfig } from '../../../api/apiConfig';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección encuestas
 * @interface SurveySection
 */
export const QRCodesSection = () => {

    const [qrusers, setQrusers] = useState([]);
    const [plans, setPlans] = useState([]);
    const [qrusersFull, setQrusersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [page, setPage] = useState(0);
    // Header para listado de encuestas
    const headers = [
        {
            "name": "CÓDIGO REGISTRO",
            "sort_name": "code",
            "width": "15%"
        },
        {
            "name": "NOMBRE USUARIO",
            "sort_name": "nameuser",
            "width": "25%"
        },
        {
            "name": "PLAN ASIGNADO",
            "sort_name": "nameplan",
            "width": "25%"
        },
        {
            "name": "ESTADO",
            "sort_name": "used",
            "width": "15%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "date",
            "width": "12%"
        }
    ];

    const [details, setDetails] = useState(null)
    const [userHovered, setUserHovered] = useState(Array(qrusers.length).fill(false))
    const [showModal, setShowModal] = useState(false);

    const [showModalCodeQR, setShowModalCodeQR] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [toastProperties, setToastProperties] = useState({show: false});
    // Función mostrar modal para cambiar usuario de B2B
    const handleOpenModalCodeQR = async () => {
        setShowModalCodeQR(true);
    }
    // Función ocultar modal para cambiar usuario de B2B
    const handleCloseModalCodeQR = () => {
        setShowModalCodeQR(false);
    }
    // Función handle para selección de selector
    const handleSelectPlanChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };
    // Función mostrar modal para cambiar estado de qruificados e insignias
    const handleOpenModal = (detail) => {
        setDetails(detail);
        setShowModal(true);
    }
    // Función cerrar modal para cambiar estado de qruificados e insignias
    const handleCloseModal = () => {
        setShowModal(false);
    }
    // Handle para asignar plan eanista a usuarios que cumplan con plan
    const handleCodeQR =  (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/couponsusers`,
            method: 'POST',
            data: { planId: selectedOption },
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha generado el código QR exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshUsers();
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al generar el código QR. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalCodeQR(false);
    }
    const [dataFilter, setDataFilter] = useState({
        name: '',
        plan: '',
        used: '',
        since: '',
        document: '',
    })
    const { authToken } = useAuthToken(true);
    const refreshUsers = () => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''      
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filterplan = dataFilter.plan !== '' ? "&plan=" + dataFilter.plan : ''
        let filterused = dataFilter.used !== '' ? "&used[$eq]=" + dataFilter.used : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/couponsusers?${filtername}${filtersince}${filterplan}${filterused}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setQrusers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshPlans = () => {
        //consulta de endpoint de planes disponibles
        const response = handleFetch({
            url: `bo/plans?companyId[$eq]=${apiConfig.company}&$limit=500`,
            method: 'GET',

        });
        response
            .then((r) => {
                setPlans(r)
            },
                error => {
                })
    }
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        refreshUsers()
        refreshPlans()
    }, [])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        refreshUsers()
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''    
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filterplan = dataFilter.plan !== '' ? "&plan=" + dataFilter.plan : ''
        let filterused = dataFilter.used !== '' ? "&used[$eq]=" + dataFilter.used : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/couponsusers?&$skip=${result}${filtername}${filterplan}${filterused}${filtersince}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setQrusers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    
    const resetState = () => {
        setUserHovered(Array(qrusers.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(qrusers.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }
    return (
        <><Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12'>
                        <div className='AcceptDialog__body'>DETALLES CÓDIGO QR</div>
                        {details && <>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div><span className='fw-bold'>CÓDIGO:</span> {details.code}</div>
                                </div>
                                <div className='col-6'>
                                <div><span className='fw-bold'>Estado:</span> {details.used===true ? 'USADO' : 'DISPONIBLE'} </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div><span className='fw-bold'>Usuario:</span> {details['webuser.name'] ? `${details['webuser.name']} ${details['webuser.lastname']}` : ' - '}</div>
                                </div>
                                <div className='col-6'>
                                    <div><span className='fw-bold'>Documento:</span> {details['webuser.document'] ? `${details['webuser.documentType']} ${details['webuser.document']}` : ' - '}</div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-12 mb-2'>
                                    <div><span className='fw-bold'>Plan Asignado:</span> {details['plan.title']}</div>
                                </div>
                                <div className='col-12'>
                                    <div><span className='fw-bold'>URL:</span> https://eanx.co/qr/{details.code}</div>
                                </div>
                            </div>
                       </>}
                    </div>
                    <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                        <div className='row w-100'>
                            <div className="col-12"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cerrar</button></div>
                        </div>
                    </div>
                </div>
        </div>
    </Modal>
    <Modal
        open={showModalCodeQR}
        onClose={handleCloseModalCodeQR}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className='B2B__modal-base ProfilePage__outline-none p-4'>
            <form onSubmit={handleCodeQR}>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12'>
                        <div className='AcceptDialog__body'>Generar Código QR para Registro</div>
                        <select name="courseId" className="form-select PlanFormContainer__input mb-3" defaultValue={selectedOption ? selectedOption : ''} onChange={handleSelectPlanChange}>
                            <option value='' key=''>Selecciona un Plan</option>
                            {
                                plans.length !== 0 &&
                                plans.map((data, index) => {
                                    return (
                                        <option value={data?.id} key={data?.id}>{data?.title}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                        <div className='row w-100'>
                            <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Crear Código</button></div>
                            <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCodeQR}>Cancelar</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Registros vía QR</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={{}} className={`LandingHeader__text-black-color-active`}  onClick={handleOpenModalCodeQR}>Crear Código QR</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listqrusers.csv"}
                            onSubmit={handleSubmit}
                            data={qrusersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de usuario</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de plan</span>
                                <div>
                                    <input type='text' name='plan' value={dataFilter?.plan || ''} onChange={handleChangeFilter} placeholder="Plan" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='number' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Estado del Código</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='used'
                                        value={dataFilter?.used || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="false">Disponible</option>
                                        <option value="true">Utilizado</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <>
            
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        qrusers.length != 0 ?
                        qrusers.map((qru, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{qru.code}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{qru['webuser.name'] ? `${qru['webuser.name']} ${qru['webuser.lastname']}` : ' - '}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{qru['plan.title']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{qru.used ? 'UTILIZADO' : 'DISPONIBLE'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(qru.createdAt).toISOString().split('T')[0]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{}} onClick={(event) => handleOpenModal(qru, event)}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from './../../pagination';
import { useLocation, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast, PwdEye } from '../../controls/UIControls';
import { PasswordValidator } from '../../controls/PasswordValidator';
import * as CONSTANTS from '../../../assets/constants';
import PhoneInput from 'react-phone-input-2';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import Modal from '@mui/material/Modal';
import CSVReader from 'react-csv-reader';
import { apiConfig } from '../../../api/apiConfig';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección usuarios
 * @interface UserAdminSection
 */
export const UserAdminSection = () => {

    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const location = useLocation();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;
    const numericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const { namePattern } = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);    
    const [showModalTypeUsersCSV, setShowModalTypeUsersCSV] = useState(false);
    const { register, handleSubmit, setValue, getValues, watch, control, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [showModalCreateUser, setShowModalCreateUser] = useState(false);
    const [showModalSyncUser, setShowModalSyncUser] = useState(false);
    const [showAge, setShowAge] = useState(false);
    const [showModalCreateUserLote, setShowModalCreateUserLote] = useState(false);
    const [showModalMoveUserLote, setShowModalMoveUserLote] = useState(false);
    const [showModalAssignCourseLote, setShowModalAssignCourseLote] = useState(false);
    const [showModalAssignPlanLote, setShowModalAssignPlanLote] = useState(false);
    const [showModalPlansIdsCSV, setShowModalPlansIdsCSV] = useState(false);
    const [plans, setPlans] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const password=watch('password','');
    const setValidPassword = (isValid) =>  {
        setPasswordIsValid(isValid);
    }
    // Función mostrar modal crear nuevo usuario
    const handleOpenModalSyncUser = async () => {        
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("address", 'aux');
        setValue("email", '');
        setValue("company", 1);
        setValue("password", 'aux');
        setShowModalSyncUser(true);
    }
    // Función cerrar modal crear nuevo usuario
    const handleCloseModalSyncUser = () => {
        setShowModalSyncUser(false);
    }
    // Función mostrar modal crear nuevo usuario
    const handleOpenModalCreateUser = async () => {
        clearAll();
        setShowModalCreateUser(true);
    }
    // Función cerrar modal crear nuevo usuario
    const handleCloseModalCreateUser = () => {
        setShowModalCreateUser(false);
    }
    // Función mostrar modal crear nuevos usuarios por lote
    const handleOpenModalCreateUserLote = async () => {
        setShowModalCreateUserLote(true);
    }
    // Función cerrar modal crear nuevos usuarios por lote
    const handleCloseModalCreateUserLote = () => {
        setShowModalCreateUserLote(false);
    }
    // Función mostrar modal trasladar usuarios B2B por lote
    const handleOpenModalMoveUserLote = async () => {
        setShowModalMoveUserLote(true);
    }
    // Función cerrar modal trasladar usuarios B2B  por lote
    const handleCloseModalMoveUserLote = () => {
        setShowModalMoveUserLote(false);
    }
    // Función mostrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleOpenModalTypeUsersCSV = async () => {
        setShowModalTypeUsersCSV(true);
    }
    // Función cerrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleCloseModalTypeUsersCSV = () => {
        setShowModalTypeUsersCSV(false);
    }
    // Función mostrar modal trasladar usuarios B2B por lote
    const handleOpenModalAssignCourseLote = async () => {
        setShowModalAssignCourseLote(true);
    }
    // Función cerrar modal trasladar usuarios B2B  por lote
    const handleCloseModalAssignCourseLote = () => {
        setShowModalAssignCourseLote(false);
    }
    // Función mostrar modal trasladar usuarios B2B por lote
    const handleOpenModalAssignPlanLote = async () => {
        setShowModalAssignPlanLote(true);
    }
    // Función cerrar modal trasladar usuarios B2B  por lote
    const handleCloseModalAssignPlanLote = () => {
        setShowModalAssignPlanLote(false);
    }
    // Función mostrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleOpenModalPlansIdsCSV = async () => {
        setShowModalPlansIdsCSV(true);
    }
    // Función cerrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleCloseModalPlansIdsCSV = () => {
        setShowModalPlansIdsCSV(false);
    }    
    const viewPlans = () => {
        const response = handleFetch({
            url: `bo/plans?$limit=500&`,
            method: 'GET',
        });
        response
            .then((r) => {
                setPlans(r)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const clearAll = () => {
        setValue("firstName", '');
        setValue("lastName", '');
        setValue("userEmail", '');
        setValue("documentType", '');
        setValue("userDocument", '');
        setValue("phone", '');
        setValue("address", '');
        setValue("password", '');
    }
    // Tipos de usuarios
    const usertypes = [
        {
            "id": "1",
            "name": "Público General",
        }, {
            "id": "2",
            "name": "Estudiantes Eanistas",
        }, {
            "id": "3",
            "name": "Docente de Planta",
        }, {
            "id": "4",
            "name": "Docente de Cátedra",
        }, {
            "id": "5",
            "name": "Colaborador",
        }, {
            "id": "6",
            "name": "Egresado",
        }
    ];
    const ContentList = ({ list, title }) => {
        return (
            <>
                <div className='text-center'>
                    {title}
                </div>
                {list?.map((b, index) => {
                    return (
                        <div className='row mb-1' key={index}>
                            <div className='col-3 B2BAdmin__text-description'>ID: {b.id}</div>
                            <div className='col-9 B2BAdmin__text-description'>{b.name||b.title}</div>
                        </div>
                    )
                })}
            </>
        )
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestDataUser = (formValues) => {
        return {
            name: formValues.firstName,
            lastname: formValues.lastName,
            email: formValues.userEmail,
            documentType: formValues.documentType,
            document: formValues.userDocument,
            phone: formValues.phone,
            address: formValues.address,
            password: formValues.password,
            isVerified: true,
            verifyChanges: {},
            type: formValues.type || 1,
            companyId: formValues.company || 1,
            segmentId: 0,
        }
    }
    // Función para retornar correo en sincronización
    const getRequestDataSyncUser = (formValues) => {
        return {
           email: formValues.userEmail
        }
    }
    // Función validar tipo de documento
    const handleSelectChange = (selectedOption) => {
        if (selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
        } else {
            setShowAge(false);
        }
        if (selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
    }
    // Handle para sincronizar usuarios en learnworlds
    const handleSyncUser = (formValues) => {
        handleFetch({
            url: 'bo/syncusers',
            method: 'POST',
            data: getRequestDataSyncUser(formValues),
            headers: { Authorization: `Bearer ${authToken}` }
        })
        .then((r) => {
            if(r.status === 200){
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: r.request,
                    position: { horizontal: 'left' },
                });
                setShowModalSyncUser(false);
                clearAll();
            }else{
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: r.request,
                    position: { horizontal: 'left' },
                });
            }
        })
        .catch((error) => {
            setToastProperties({
                show: true,
                type: 'error',
                message: error.request,
                position: { horizontal: 'left' },
            });
        });
        refreshUsers();
    }
    // Handle para crear usuario
    const handleCreateUser = (formValues) => {
        handleFetch({
            url: 'bo/webusers',
            method: 'POST',
            data: getRequestDataUser(formValues),
            headers: { Authorization: `Bearer ${authToken}` }
        })
        .then(() => {
            setShowModalCreateUser(false);
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha registrado el usuario exitosamente.',
                position: { horizontal: 'left' },
            });
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al registrar el usuario. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
        clearAll();
        refreshUsers();
    }
    const refreshUsers = () => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId=" + dataFilter.companyId : ''
        const response = handleFetch({
            url: `bo/webusers?${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}${filtercompany}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const handleCSVUpload = async (data) => {
        const requiredFields = ['name', 'lastname', 'email', 'documentType', 'document', 'phone', 'address', 'password', 'isVerified', 'verifyChanges', 'type', 'companyId', 'segmentId'];
        const normalizeString = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };
        if (requiredFields.every(field => data[0].includes(field))) {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Formato del CSV es Correcto',
                message: 'Se está procesando la carga del archivo, por favor espere',
                position: { horizontal: 'left' },
            });
            try {
                for (let i = 1; i < data.length; i++) {
                    const row = data[i].map(normalizeString);;
                    await handleFetch({
                    url: 'bo/webusers',
                    method: 'POST',
                    headers: { Authorization: `Bearer ${authToken}` },
                    data: { name: row[0],
                            lastname: row[1],
                            email: row[2],
                            documentType: row[3],
                            document: row[4],
                            phone: row[5],
                            address: row[6],
                            password: row[7],
                            isVerified: row[8] || true,
                            verifyChanges: row[9] || {},
                            type: row[10] || 1,
                            companyId: row[11] || 1,
                            segmentId: row[12] || 0}
                    })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        if(error?.errors[0]?.message == "email must be unique"){
                            setToastProperties({
                                show: true,
                                type: 'error',
                                message: 'Existen usuarios en el CSV ya registrados en Base de datos.',
                                position: { horizontal: 'left' },
                            });
                        }else{
                            setToastProperties({
                                show: true,
                                type: 'error',
                                message: 'Error al agregar usuarios al B2B. Intenta nuevamente.',
                                position: { horizontal: 'left' },
                            });

                        }
                    });
                }
                } catch (error) {
                console.error(error);
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error en formato del CSV, revisa que el formato sea el correcto.',
                    position: { horizontal: 'left' },
                });
                }
            refreshUsers();
            setShowModalCreateUserLote(false);
        }
      }
    // Trasladar usuarios B2B a EanX por Lote
    const handleCSVTraslateB2BUsers = async (data) => {
        const requiredFields = ['name', 'lastname', 'email', 'documentType', 'document', 'type', 'companyId', 'segmentId'];
        if (requiredFields.every(field => data[0].includes(field))) {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Formato del CSV es Correcto',
                message: 'Se está procesando la carga del archivo, por favor espere',
                position: { horizontal: 'left' },
            });
            const updatedValues = [];
            const errorValues = [];
            try {
                for (let i = 1; i < data.length; i++) {
                    const row = data[i];
                    await handleFetch({
                        url: `bo/webusers?&email=${row[2]}&document=${row[4]}`,
                        method: 'GET',
                        headers: { Authorization: `Bearer ${authToken}` }
                    })
                        .then(async (r) => {
                            if(r.data && r.data[0]){
                                await handleFetch({
                                url: `bo/webusers/${ r.data[0].id}`,
                                method: 'PATCH',
                                headers: { Authorization: `Bearer ${authToken}` },
                                data: { name: row[0],
                                        lastname: row[1],
                                        email: row[2],
                                        documentType: row[3],
                                        document: row[4],
                                        type: 1,
                                        companyId: 1,
                                        segmentId: row[5]}
                                })
                                .then((response) => {
                                    updatedValues.push({
                                        name: r.data[0].name,
                                        lastname: r.data[0].lastname,
                                        email: r.data[0].email,
                                        operation: 'EXITOSO TRASLADO USUARIO',
                                    });
                                })
                                .catch((error) => {
                                    errorValues.push({
                                    name: row[0],
                                    lastname: row[1],
                                    email: row[2],
                                    operation: 'ERROR AL TRASLADAR AL USUARIO',
                                  });
                                });

                            }else{
                                errorValues.push({
                                    name: row[0],
                                    lastname: row[1],
                                    email: row[2],
                                    operation: 'USUARIO NO ENCONTRADO',
                                  });
                            }
                        },
                            error => {
                                console.log(error.errors);
                            })
                }
                } catch (error) {
                console.error(error);
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error en formato del CSV, revisa que el formato sea el correcto.',
                    position: { horizontal: 'left' },
                });
                }
                const csvData = [...updatedValues, ...errorValues];
                const csvContent = Papa.unparse(csvData, {
                quotes: true,
                quoteChar: '"',
                });
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'usuarios_actualizados.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setShowModalCreateUserLote(false);
        }
      }
// Asignar Cursos a usuarios Lote
const handleCSVAssignCoursesUsers = async (data) => {
    const requiredFields = ['documentType', 'document', 'courseId', 'CodigoOperacion'];
    if (requiredFields.every(field => data[0].includes(field))) {
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Formato del CSV es Correcto',
            message: 'Se está procesando la carga del archivo, por favor espere',
            position: { horizontal: 'left' },
        });
        const updatedValues = [];
        const errorValues = [];
        try {
            for (let i = 1; i < data.length; i++) {
                const row = data[i];
                await handleFetch({
                    url: `bo/webusers?&documentType=${row[0]}&document=${row[1]}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${authToken}` }
                })
                    .then(async (r) => {
                        if(r.data && r.data[0]){
                            await handleFetch({
                                url: 'users/plans/users_courses',
                                method: 'POST',
                                data: {
                                    userId: r.data[0].id,
                                    courseId: row[2],
                                    approvalCode: row[3],
                                    companyId: apiConfig.company
                                }
                            })
                            .then((response) => {
                                updatedValues.push({
                                    name: r.data[0].name,
                                    lastname: r.data[0].lastname,
                                    email: r.data[0].email,
                                    operation: 'CURSO ASIGNADO EXITOSAMENTE',
                                });
                            })
                            .catch((error) => {
                                errorValues.push({
                                name: row[0],
                                lastname: row[1],
                                email: row[2],
                                operation: 'ERROR AL ASIGNAR CURSO AL USUARIO',
                              });
                            });

                        }else{
                            errorValues.push({
                                name: row[0],
                                lastname: row[1],
                                email: row[2],
                                operation: 'USUARIO NO ENCONTRADO',
                              });
                        }
                    },
                        error => {
                            console.log(error.errors);
                        })
            }
            } catch (error) {
            console.error(error);
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error en formato del CSV, revisa que el formato sea el correcto.',
                position: { horizontal: 'left' },
            });
            }
            const csvData = [...updatedValues, ...errorValues];
            const csvContent = Papa.unparse(csvData, {
            quotes: true,
            quoteChar: '"',
            });
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'usuarios_cursos_asignados.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setShowModalAssignCourseLote(false);
    }
    refreshUsers();
  }
// Asignar Plan a usuarios Lote
const handleCSVAssignPlanUsers = async (data) => {
    const requiredFields = ['documentType', 'document', 'planId', 'CodigoOperacion'];
    if (requiredFields.every(field => data[0].includes(field))) {
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Formato del CSV es Correcto',
            message: 'Se está procesando la carga del archivo, por favor espere',
            position: { horizontal: 'left' },
        });
        const updatedValues = [];
        const errorValues = [];
        try {
            for (let i = 1; i < data.length; i++) {
                const row = data[i];
                await handleFetch({
                    url: `bo/webusers?&documentType=${row[0]}&document=${row[1]}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${authToken}` }
                })
                    .then(async (r) => {
                        if(r.data && r.data[0]){
                            await handleFetch({
                                url: 'users/plans',
                                method: 'POST',
                                data: {
                                    userId: r.data[0].id,
                                    planId: row[2],
                                    approvalCode: row[3],
                                }
                            })
                            .then((response) => {
                                updatedValues.push({
                                    name: r.data[0].name,
                                    lastname: r.data[0].lastname,
                                    email: r.data[0].email,
                                    operation: 'PLAN ASIGNADO EXITOSAMENTE',
                                });
                            })
                            .catch((error) => {
                                errorValues.push({
                                name: row[0],
                                lastname: row[1],
                                email: row[2],
                                operation: 'ERROR AL ASIGNAR PLAN AL USUARIO',
                              });
                            });

                        }else{
                            errorValues.push({
                                name: row[0],
                                lastname: row[1],
                                email: row[2],
                                operation: 'USUARIO NO ENCONTRADO',
                              });
                        }
                    },
                        error => {
                            console.log(error.errors);
                        })
            }
            } catch (error) {
            console.error(error);
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error en formato del CSV, revisa que el formato sea el correcto.',
                position: { horizontal: 'left' },
            });
            }
            const csvData = [...updatedValues, ...errorValues];
            const csvContent = Papa.unparse(csvData, {
            quotes: true,
            quoteChar: '"',
            });
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'usuarios_plan_asignados.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setShowModalAssignPlanLote(false);
    }
    refreshUsers();
  }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const [dataFilter, setDataFilter] = useState({
        document: '',
        email: '',
        lastname: '',
        name: '',
        companyId: '',
        until: '',
        since: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId=" + dataFilter.companyId : ''
        window.history.replaceState(null, '');
        const responsecomp = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecomp
            .then((r) => {
                setCompanies(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        refreshUsers();
        viewPlans();
        // listado de usuarios para crear csv
        const responsefull = handleFetch({
            url: `bo/webuserscsv?$limit=50000${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}${filtercompany}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                let _filteredUsers = [];
                let today = new Date().toISOString();
                r.data.forEach(user => {
                    let flag = false;
                    let tIndex = -1;
                    user["createdAt"] = new Date(user["createdAt"]).toISOString().split('T')[0];
                    user["lastLogin"] = user["lastLogin"] ? new Date(user["lastLogin"]).toISOString().split('T')[0] : null;
                    user["plans.users_plans.endsAt"] = user["plans.users_plans.endsAt"] ? new Date(user["plans.users_plans.endsAt"]).toISOString().split('T')[0] : null;
                    if (!!user["plans.users_plans.endsAt"] && user["plans.users_plans.endsAt"] < today) {
                        user["plans.title"] = user["plans.title"] + " - Vencido";
                    }
                    if (_filteredUsers.length < 1) {
                        _filteredUsers.push(user);
                    } else {
                        _filteredUsers.forEach((filteredUser, index) => {
                            if (user.id == filteredUser.id) {
                                flag = true;
                                tIndex = index;
                            }
                        });
                        if (flag) {
                            // _filteredUsers
                            if (user["plans.users_plans.endsAt"] == null) {
                                _filteredUsers.push(user);
                            } else if (user["plans.users_plans.endsAt"] > _filteredUsers[tIndex]["plans.users_plans.endsAt"]) {
                                _filteredUsers[tIndex] = user;
                            }
                        } else {
                            _filteredUsers.push(user);
                        }
                    }
                });
                setUsersFull(_filteredUsers);
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId=" + dataFilter.companyId : ''
        refreshUsers();
        // Llamado del endpoint para crear csv con filtros
        const responsefull = handleFetch({
            url: `bo/webuserscsv?$limit=50000${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}${filtercompany}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                let _filteredUsers = [];
                let today = new Date().toISOString();
                r.data.forEach(user => {
                    let flag = false;
                    let tIndex = -1;
                    if (!!user["plans.users_plans.endsAt"] && user["plans.users_plans.endsAt"] < today) {
                        user["plans.title"] = user["plans.title"] + " - Vencido";
                    }
                    if (_filteredUsers.length < 1) {
                        _filteredUsers.push(user);
                    } else {
                        _filteredUsers.forEach((filteredUser, index) => {
                            if (user.id == filteredUser.id) {
                                flag = true;
                                tIndex = index;
                            }

                        });
                        if (flag) {
                            // _filteredUsers
                            if (user["plans.users_plans.endsAt"] == null) {
                                _filteredUsers.push(user);
                            } else if (user["plans.users_plans.endsAt"] > _filteredUsers[tIndex]["plans.users_plans.endsAt"]) {
                                _filteredUsers[tIndex] = user;
                            }
                        } else {
                            _filteredUsers.push(user);
                        }
                    }
                });
                setUsersFull(_filteredUsers);
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <Modal
                open={showModalSyncUser}
                onClose={handleCloseModalSyncUser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleSyncUser)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body mb-4'>Sincronizar usuario con Learnworlds</div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-12">
                                            <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span> <span className='auth__comment'>El utilizado por el usuario en plataforma</span></label>
                                            <input
                                                type='text'
                                                name='userEmail'
                                                className={`form-control auth__input 
                                ${errors.userEmail ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                                {...register("userEmail", {
                                                    required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                                    pattern: {
                                                        value: CONSTANTS.PATTERN_EMAIL,
                                                        message: CONSTANTS.MESSAGE_EMAIL_INVALID
                                                    }
                                                })}
                                            />
                                            {errors.userEmail && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.userEmail.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Sincronizar Usuario</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalSyncUser}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalCreateUser}
                onClose={handleCloseModalCreateUser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateUser)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body mb-4'>Agregar Nuevo Usuario</div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                                            <input
                                                type='text'
                                                name='firstName'
                                                className={`form-control auth__input 
                                ${errors.firstName ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                                                {...register("firstName", {
                                                    required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                                                    pattern: {
                                                        value: namePattern,
                                                        message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                                    }
                                                })}
                                            />
                                            {errors.firstName && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.firstName.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                                            <input
                                                type='text'
                                                name='lastName'
                                                className={`form-control auth__input 
                                ${errors.lastName ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                                                {...register("lastName", {
                                                    required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                                                    pattern: {
                                                        value: namePattern,
                                                        message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                                    }
                                                })}
                                            />
                                            {errors.lastName && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.lastName.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Tipo de documento </label>
                                            <select
                                                defaultValue=""
                                                className={`form-select auth__select 
                                ${errors.documentType ? "auth__input--error" : ""}`}
                                                aria-label="Default select example"
                                                name='documentType'
                                                {...register("documentType", {
                                                    required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                                    onChange: handleSelectChange
                                                })}
                                            >
                                                <option value="">Selecciona el tipo de documento</option>
                                                <option value="CC">Cédula de Ciudadanía</option>
                                                <option value="CE">Cédula de Extranjería</option>
                                                <option value="DIE">Documento de Identidad Extranjera</option>
                                                <option value="PA">Pasaporte</option>
                                                <option value="TI">Tarjeta de Identidad</option>
                                                <option value="PEP">Permiso Especial de Permanencia</option>
                                            </select>
                                            {errors.documentType && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.documentType.message}
                                                </span>
                                            )}


                                            {
                                                showAge ? (
                                                    <div className='show-form-age'>
                                                        <div className="form-check">
                                                            <input className="form-check-input auth__form-check"
                                                                type="checkbox"
                                                                value=""
                                                                {...register("olderThan12", { required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED })}
                                                            />
                                                            <label className='form-check-label auth__form-label' style={{ fontSize: "12px" }}>
                                                                Declaro ser mayor de 12 años.
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className='form-check-label auth__form-label' style={{ fontSize: "12px", paddingBottom: "12px" }}>
                                                                Si eres menor de 12 años requieres autorización expresa de tus padres o tutores,
                                                                pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                                                            </label>
                                                        </div>
                                                        {errors.olderThan12 && (
                                                            <div role="alert" className='auth__inputError'>
                                                                {errors.olderThan12.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                                            <input
                                                type='text'
                                                name='userDocument'
                                                className={`form-control auth__input 
                                ${errors.userDocument ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                                                {...register("userDocument", {
                                                    required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                                                    pattern: {
                                                        value: docPattern,
                                                        message: docMessage
                                                    }
                                                })}
                                            />
                                            {errors.userDocument && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.userDocument.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Teléfono <span className='auth__required-input'>*</span></label>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                className="auth__select"
                                                rules={{ required: CONSTANTS.MESSAGE_PHONE_REQUIRED }}
                                                render={({ field }) =>
                                                    <PhoneInput {...field}
                                                        inputClass={`auth__input-phone 
                                ${errors.phone ? "auth__input--error" : ""}`}
                                                        specialLabel=''
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            placeholder: CONSTANTS.LABEL_PHONE_PLACEHOLDER
                                                        }}
                                                        country={'co'} />}
                                            />
                                            {errors.phone && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.phone.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Dirección <span className='auth__required-input'>*</span></label>
                                            <input
                                                type='text'
                                                name='address'
                                                className={`form-control auth__input 
                                ${errors.address ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_ADDRESS_PLACEHOLDER}
                                                {...register("address", { required: CONSTANTS.MESSAGE_ADDRESS_REQUIRED })}
                                            />
                                            {errors.address && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.address.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                        <label className='auth__form-label'>Compañia <span className='auth__required-input'>*</span></label>
                                        <select
                                                defaultValue=""
                                                className={`form-select auth__select 
                                ${errors.company ? "auth__input--error" : ""}`}
                                                aria-label="Default select example"
                                                name='company'
                                                {...register("company", {
                                                    required: CONSTANTS.MESSAGE_COMPANY_REQUIRED,
                                                    onChange: handleSelectChange
                                                })}
                                            >
                                                <option value="">Selecciona una compañia</option>
                                                {companies?.map((company, index) => {
                                                    return (
                                                        <option value={company.id}>{company.name}</option>
                                                    )
                                                })}
                                                {errors.company && (
                                                    <span role="alert" className='auth__inputError'>
                                                        {errors.company.message}
                                                    </span>
                                                )}
                                            </select>                                           
                                        </div>
                                        <div className="col-6">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span> <span className='auth__comment'>Será su usuario en plataforma</span></label>
                                            <input
                                                type='text'
                                                name='userEmail'
                                                className={`form-control auth__input 
                                ${errors.userEmail ? "auth__input--error" : ""}`}
                                                placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                                {...register("userEmail", {
                                                    required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                                    pattern: {
                                                        value: CONSTANTS.PATTERN_EMAIL,
                                                        message: CONSTANTS.MESSAGE_EMAIL_INVALID
                                                    }
                                                })}
                                            />
                                            {errors.userEmail && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.userEmail.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className='auth__form-label'>Contraseña <span className='auth__required-input'>*</span></label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                                                render={({ field: { onChange, onBlur, name }, fieldState: { invalid } }) => {
                                                    return (
                                                        <PwdEye
                                                            className={`form-control auth__input ${invalid ? "auth__input--error" : ""}`}
                                                            placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={invalid}
                                                        >
                                                        </PwdEye>)
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="col-12">
                                        <PasswordValidator
                                            value={password}
                                            checkCriteria={setValidPassword}
                                        ></PasswordValidator>                                        
                                        {!passwordIsValid && (
                                                <span role="alert" className='auth__inputError'>
                                                    {CONSTANTS.MESSAGE_PASSWORD_IVALID}
                                                </span>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Registrar Usuario</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateUser}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalCreateUserLote}
                onClose={handleCloseModalCreateUserLote}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Registrar Usuarios Por Lote</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','csv-registrar-usuarios-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">Para ver el listado de tipos de usuario y Códigos por Empresa haz <Link to={{}} className="text_primary fw-bold" onClick={handleOpenModalTypeUsersCSV}>click aquí</Link></div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVUpload} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar la carga</span></div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showModalTypeUsersCSV}
                onClose={handleCloseModalTypeUsersCSV}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Tipos de Usuarios y Compañias B2B</div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <ContentList list={usertypes} title="type"/>
                                </div>
                                <div className='col-6'>
                                    <ContentList list={companies} title="companyId"/>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalTypeUsersCSV}>CERRAR</button></div>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </Modal>
            <Modal
                open={showModalMoveUserLote}
                onClose={handleCloseModalMoveUserLote}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Trasladar usuarios B2B a EanX Lote</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','csv-pasar-usuarios-b2b-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">Para ver el listado de tipos de usuario y Códigos por Empresa haz <Link to={{}} className="text_primary fw-bold" onClick={handleOpenModalTypeUsersCSV}>click aquí</Link></div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVTraslateB2BUsers} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar el traslado</span></div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showModalAssignCourseLote}
                onClose={handleCloseModalAssignCourseLote}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Asignar Cursos a usuarios por Lote</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','csv-asignar-cursos-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">El courseId de cada curso se encuentra en la URL de dicho curso<br /><br />
                             Ejemplo<br />https://eanx.co/curso/capsula-innovacion-emprendimiento-climatico <br />
                             courseId: capsula-innovacion-emprendimiento-climatico<br /><br />
                             </div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVAssignCoursesUsers} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar el traslado</span></div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showModalAssignPlanLote}
                onClose={handleCloseModalAssignPlanLote}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Asignar Plan a usuarios por Lote</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','csv-asignar-plan-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">Para ver el listado de planes y códigos haz <Link to={{}} className="text_primary fw-bold" onClick={handleOpenModalPlansIdsCSV}>click aquí</Link></div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVAssignPlanUsers} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar el traslado</span></div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showModalPlansIdsCSV}
                onClose={handleCloseModalPlansIdsCSV}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Id de Plan para Asignar por Lote</div>
                            </div>
                            <div className='row' style={{ maxHeight: "60vh", marginTop: "17px", overflowY: "auto" }}>
                                <div className='col-6'>
                                    <ContentList list={plans && plans.slice(0, Math.ceil(plans.length / 2))} title="PLAN"/>
                                </div>
                                <div className='col-6'>
                                    <ContentList list={plans && plans.slice(Math.ceil(plans.length / 2))} title="PLAN"/>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalPlansIdsCSV}>CERRAR</button></div>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </Modal>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Usuarios</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listusers.csv"}
                            onSubmit={onSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='number' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Compañia B2B</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='companyId'
                                        value={dataFilter?.companyId || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        {
                                            companies.length != 0 ?
                                                companies.map((companie, index) => {
                                                    return (<option value={companie.id}>{companie.name}</option>)
                                                }) : ''
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Fin</span>
                                <div>
                                    <input type="date" name="until" value={dataFilter?.until || ''} onChange={handleChangeFilter} min={dataFilter?.since} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateUser}>
                                    <span>Registrar</span>
                                </Link>
                                <Link to={"/admin/registrosqr"} className="FilterAdmin__option-button">
                                    <span>Reg. QR</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateUserLote}>
                                    <span>Reg. LT</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalMoveUserLote}>
                                    <span>Trasl. usua. B2B</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalAssignCourseLote}>
                                    <span>Asig. curso LT</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalAssignPlanLote}>
                                    <span>Asig. plan LT</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalSyncUser}>
                                    <span>Learnworlds</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE USUARIO",
            "sort_name": "title",
            "width": "17%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "enabled",
            "width": "25%"
        },
        {
            "name": "FECHA INSCRIPCIÓN",
            "sort_name": "highlighted",
            "width": "18%"
        },
        {
            "name": "PLAN ADQUIRIDO",
            "sort_name": ["durationPeriod", "duration"],
            "width": "20%"
        },
        {
            "name": "ÚLTIMA SESIÓN",
            "sort_name": "lastlogin",
            "width": "15%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () => {
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        setPage(event.selected)
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId=" + dataFilter.companyId : ''
        const response = handleFetch({
            url: `bo/webusers?${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}${filtercompany}&$skip=${result}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usersSorted.length != 0 ?
                            usersSorted.map((user, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user.name} {user.lastname}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}><div className="UserAdmin__table-td">{user.email}</div></td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user['plans.title'] ? user['plans.title'] : 'No tiene plan asignado'}</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{user.lastLogin ? new Date(user.lastLogin).toISOString().split('T')[0] : ' - '}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{
                                                                pathname: `/admin/usuarios/editar/${user.id}`
                                                            }}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}